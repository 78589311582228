import { Box, Container, TextField, Button, Typography, Grid } from "@mui/material";
import useAddCategory from "../../components/admin/Categories/hooks/useAddCategory"; 

export const AddCategories = () => {
  const {
    category,
    imageUploading,
    handleCategoryNameChange,
    handleImageChange,
    handleAddCategory,
  } = useAddCategory();

  return (
    <Box py={8}>
       <Typography variant="h5" component="h1" sx={{ fontWeight: "bold", pb: 2, textAlign: "center", color: "primary.main", fontFamily: "Poppins-Bold" }}>
        Add Category
      </Typography>
      <Container maxWidth="md" sx={{ py: 3 }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Category Name"
                variant="outlined"
                value={category.name}
                onChange={handleCategoryNameChange}
                fullWidth
              />
              <Button variant="contained" color="primary" onClick={handleAddCategory} sx={{ mt: 7, display: ["none", "block"] }} fullWidth>
                Add Category
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ width: '100%', height: "150px", border: '1px dashed grey', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                {category.picture ? (
                  <img src={category.picture} alt="category" style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }} />
                ) : (
                  <Box variant="outlined" component="label" htmlFor="file-upload" sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                    <input
                      id="file-upload"
                      type="file"
                      onChange={handleImageChange}
                      hidden
                    />
                    {imageUploading ? 'Uploading...' : 'Upload Image'}
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ display: ["block", "none"] }}>
              <Button variant="contained" color="primary" onClick={handleAddCategory} fullWidth>
                Add Category
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};
