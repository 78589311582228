import { useEffect } from "react";
import axiosInstance from "../../../../../axios/axiosInstance";

export const useFetchUser = (id, setUser) => {
    useEffect(() => {
        const handleFetchUser = () => {
            axiosInstance
                .get(
                    `/user/${id}`
                )
                .then((res) => {
                    setUser({
                        username: res.data.user.username ? res.data.user.username : "",
                        firstName: res.data.user.firstName ? res.data.user.firstName : "",
                        lastName: res.data.user.lastName ? res.data.user.lastName : "",
                        phoneNumber: res.data.user.phoneNumber
                            ? res.data.user.phoneNumber
                            : "",
                        email: res.data.user.email ? res.data.user.email : "",
                        address: res.data.user.address
                            ? {
                                country: res.data.user.address.country
                                    ? res.data.user.address.country
                                    : "",
                                state: res.data.user.address.state
                                    ? res.data.user.address.state
                                    : "",
                                zipCode: res.data.user.address.zipCode
                                    ? res.data.user.address.zipCode
                                    : "",
                            }
                            : {
                                country: "",
                                state: "",
                                zipCode: "",
                            },
                    });
                })

        };
        handleFetchUser();
    }, [id, setUser]);
};