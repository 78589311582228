import { useEffect, useState } from "react";
import axiosInstance from "../axios/axiosInstance";

export const useGetCategories = () => {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        axiosInstance.get("/products/categories").then((res) => {
            setCategories(res.data);
        }).catch(() => {
            setCategories([]);
        });

    }, []);
    return categories;
}