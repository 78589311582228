import { Box, Container, TextField, Button, Typography, Grid } from "@mui/material";
import UpgradeIcon from '@mui/icons-material/Upgrade';
import useEditCategory from "../../components/admin/Categories/hooks/useEditCategory"; 

export const EditCategories = () => {
  const {
    category,
    imageUploading,
    handleCategoryNameChange,
    handleImageChange,
    handleEditCategory,
  } = useEditCategory();

  return (
    <Box py={8}>
      <Typography variant="h4" sx={{ color: "primary.main", textAlign: "center", fontWeight: "bold", pb: 8 }}>
        Edit Category
      </Typography>
      <Container maxWidth="md" sx={{ py: 3 }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Category Name"
                variant="outlined"
                value={category.name}
                onChange={handleCategoryNameChange}
                fullWidth
              />
              <Button variant="contained" color="primary" onClick={handleEditCategory} sx={{ mt: 7, display: ["none", "block"] }} fullWidth>
                Save Edit
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ width: '100%', height: "150px", border: '1px dashed grey', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                <img src={category.picture} alt={category.name} style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "20px" }} />
                <label htmlFor="file-upload" style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                  <input id="file-upload" type="file" onChange={handleImageChange} hidden />
                  {!imageUploading && (
                    <Box sx={{ backgroundColor: "white", padding: "10px", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <UpgradeIcon sx={{ cursor: "pointer", color: "secondary.main", fontSize: "40px" }} />
                    </Box>
                  )}
                </label>
                {imageUploading && (
                  <Box sx={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', zIndex: "1000", backgroundColor: "#808080a8", color: "secondary.main", fontWeight: "bold" }}>
                    Uploading...
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ display: ["block", "none"] }}>
              <Button variant="contained" color="primary" onClick={handleEditCategory} fullWidth>
                Save Edit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};
