import { Box, Container, Grid, Paper, Typography, } from "@mui/material"
import { useEffect, useState } from "react";
import axiosInstance from "../../axios/axiosInstance";
import { useDispatch } from "react-redux";
import { setActiveCategory } from "../../redux/slices/filterSlice";
import { useNavigate } from "react-router-dom";
import { CategoriesSkeleton } from "../general/loading/CategoriesSkeleton";
import { useTranslation } from "react-i18next";
export const Categories = () => {
  const { t } = useTranslation("categories");
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // set active category in redux store when clicked on a category and redirect to shop page

  const handleItemClick = (category) => {
    dispatch(setActiveCategory(category));
    navigate("/shop");
  };

  // get all categories from server
  useEffect(() => {
    setLoading(true);
    axiosInstance.get("/products/categories").then((res) => {
      setCategories(res.data);
      setLoading(false);
    }).catch(() => {
      setCategories([]);
    });

  }, []);
  return (
    <Box sx={{ py: 8 }}>
      <Container  sx={{height:categories.length ===0?"100vh":"auto"}}>
        <Typography variant="h4" sx={{ color: "primary.main", textAlign: "center", fontWeight: "bold", pb: 8,fontFamily:"Poppins-Bold" }}>
          {t('categories')} 
        </Typography>
        <Grid container spacing={5}>
          {
            loading ?
              ["", "", "", "", "", "", "", "", ""].map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index} >
                  <CategoriesSkeleton/>
                </Grid>
              )

              ) : categories.map((category) => (
                <Grid item xs={12} sm={6} md={4} key={category._id} onClick={() => handleItemClick(category)}>
                  <Paper sx={{ cursor: "pointer", color: "secondary.main", textAlign: "center", borderRadius: "50px" }}>
                    <img src={category?.picture} alt={category?.name} style={{ width: "100%", height: "200px", objectFit: "cover", borderRadius: "50px 50px 0px 0px" }} />
                    <Typography variant="body1" sx={{ py: 2, fontWeight: "bold" }}>{category?.name}</Typography>
                  </Paper>
                </Grid>
              ))
          }
        </Grid>
      </Container>
    </Box>
  )
}
