import { useTranslation } from "react-i18next";
import { useSendMessageValidation } from "../../validation/SendMessageValidation"
import { useSendMessage } from "./hooks/sendMessage";
import BaseForm from "../../formik/BaseForm";
import CustomFormikField from "../../formik/CustomFormikField";
import SubmitButton from "../../formik/SubmitButton";
const MessageForm = () => {
    const validationSchema = useSendMessageValidation();
    const { handleSubmit, loading } = useSendMessage();
    const { t } = useTranslation("sendMessage");
    const initialValues = {
        message: "",
    }
    return (
        <BaseForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            <CustomFormikField name="message" label={t("formLabels.message")} type="text" rows={5} />

            <SubmitButton loading={loading} >
                {t("formBtn")}
            </SubmitButton>

        </BaseForm>
    )
}

export default MessageForm