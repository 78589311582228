import { useState } from "react";
import axiosInstance from "../../../../axios/axiosInstance";
import { uploadImage } from "../../../../helper/UploadImage";
import { useShowAlert } from "../../../../hooks/useShowAlert";

const useAddCategory = () => {
  const { showAlert } = useShowAlert();
  const [category, setCategory] = useState({ name: "", picture: "" });
  const [imageUploading, setImageUploading] = useState(false);
  // handle category name change
  const handleCategoryNameChange = (event) => {
    setCategory({ ...category, name: event.target.value });
  };

  // handle image change
  const handleImageChange = async (event) => {
    try {
      setImageUploading(true);
      const selectedImage = event.target.files[0];
      if (!selectedImage.type.startsWith("image/")) {
        showAlert("error", "Unsupported file format, only images are allowed");
        setImageUploading(false);
        return;
      }
      const imageUrl = await uploadImage(selectedImage);
      if (!imageUrl) {
        showAlert("error", "Image upload failed");
      } else {
        setCategory({ ...category, picture: imageUrl });
      }
    } catch (err) {
      showAlert('error', err.message);
    } finally {
      setImageUploading(false);
    }
  };

  // handle add category
  const handleAddCategory = () => {
    axiosInstance.post("admin/categories/create", category).then(() => {
      showAlert('success', "Category added successfully");
      setCategory({ name: "", picture: "" });
    }).catch((err) => {
      showAlert('error', err.response.data.error);
    });
  };

  return {
    category,
    imageUploading,
    handleCategoryNameChange,
    handleImageChange,
    handleAddCategory,
  };
};

export default useAddCategory;
