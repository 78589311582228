import { Box, Card, CardMedia, Typography, CardContent, CardActions, Stack, Avatar } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ShareProducts } from "../general/ShareProducts";
import { ControlFavoriteProduct } from "../Favourites/ControlFavoriteProduct";

export const ProductItem = ({ product }) => {
    const navigate = useNavigate();

    const navigateToPageDetails = (id) => {
        navigate(`/product-detail/${id}`);
    };

    return (
        <Card
            sx={{
                transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                boxShadow: "0 5px 10px rgba(0, 0, 0, 0.2)",
                position: "relative",
                borderRadius: "20px",
            }}
        >
            <Stack
                sx={{
                    position: "absolute",
                    top: "5px",
                    right: "50px",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <ControlFavoriteProduct product={product} />
            </Stack>
            <Stack
                sx={{
                    p: 2,
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    backgroundColor: "shadow.main",
                    border: "1px solid gray",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <ShareProducts
                    shareUrl={`${import.meta.env.VITE_VERCEL_SHARE_PRODUCTURL}/${product?._id}`}
                    title={product?.name}
                />
            </Stack>
            <CardMedia
                onClick={() => navigateToPageDetails(product?._id)}
                component="img"
                alt={product.name}
                loading="lazy"
                height="150px"
                width="100%"
                image={product?.pictures ? product?.pictures[0] : ""}
                sx={{ cursor: "pointer" }}
            />
            <CardContent>
                <Typography
                    gutterBottom
                    variant="body1"
                    component="h2"
                    sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                >
                    {product?.name}
                </Typography>
                <Box>
                    <Typography
                        gutterBottom
                        variant="caption"
                        component="span"
                        sx={{ color: "primary.main" }}
                    >
                        <Typography
                            variant="body1"
                            component="span"
                            sx={{ fontWeight: "bold" }}
                        >
                            ${product?.price}
                        </Typography>
                        / {product?.per}
                    </Typography>
                </Box>
            </CardContent>
            <CardActions>
                <Stack
                    direction="row"
                    sx={{ justifyContent: "space-between", width: "100%" }}
                >
                    <Box>
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{ alignItems: "center", cursor: "pointer" }}
                        >
                            <Avatar
                                sx={{
                                    textDecoration: "none",
                                    width: "30px",
                                    height: "30px",
                                }}
                                component={Link}
                                to={`/rent-profile/${product?.userID?._id}`}
                            >
                                {product.userID.profilePicture ? (
                                    <img
                                        src={product?.userID?.profilePicture}
                                        alt={product?.userID?.username || product?.userID?.displayName}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                        loading="lazy"
                                    />
                                ) : (
                                    product?.userID?.username?.[0].toUpperCase() ||
                                    product?.userID?.displayName?.[0].toUpperCase()
                                )}
                            </Avatar>
                            <Typography
                                gutterBottom
                                variant="body2"
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    lineHeight: "30px",
                                    color: "primary.main",
                                    textDecoration: "none",
                                }}
                                component={Link}
                                to={`/rent-profile/${product?.userID?._id}`}
                            >
                                {product?.userID?.username}
                            </Typography>
                        </Stack>
                    </Box>
                </Stack>
            </CardActions>
        </Card>
    );
};
