// import svg star icon
import { Avatar, Box, Card, CardActions, CardContent, CardMedia, Stack, Typography } from "@mui/material"
import { ControlFavoriteProduct } from '../../Favourites/ControlFavoriteProduct';
import { ShareProducts } from '../../general/ShareProducts';
import { Link, useNavigate } from 'react-router-dom';
export const ProductCard = ({ transaction }) => {
    const { productID, ownerID } = transaction
    const navigate = useNavigate();
    const navigatToPageDetials = (id) => {
        // route to productID details page
        navigate(`/product-detail/${id}`);
    };

    return (
        <Card
            sx={{
                transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                boxShadow: "0 5px 10px rgba(0, 0, 0, 0.2)",
                ":hover": {
                    transform: "scale(1.05) rotate(1deg)",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                },
                position: "relative",
                borderRadius: "20px"
            }}
        >
            <Stack sx={{ position: "absolute", top: "5px", right: "50px", borderRadius: "50%", width: "30px", height: "30px", justifyContent: "center", alignItems: "center" }}>
                <ControlFavoriteProduct product={productID} />
            </Stack>
            <Stack sx={{ p: 2, position: "absolute", top: "5px", right: "5px", backgroundColor: "shadow.main", borderRadius: "50%", width: "30px", height: "30px", justifyContent: "center", alignItems: "center",border:"1px solid gray" }}>
                <ShareProducts shareUrl={`${import.meta.env.VITE_VERCEL_SHARE_PRODUCTURL}/${productID?._id}`} title={productID?.name} />
            </Stack>
            <CardMedia
                onClick={() => navigatToPageDetials(productID?._id)}
                component="img"
                alt={productID?.name}
                loading="lazy"
                height="150px"
                width="100%"
                image={productID?.pictures ? productID?.pictures[0] : ""}
                sx={{ cursor: "pointer" }}
            />
            <CardContent>
                <Typography
                    gutterBottom
                    variant="body1"
                    component="h2"
                    sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                >
                    {

                        productID?.name
                    }
                </Typography>
                <Box>
                    <Typography
                        gutterBottom
                        variant="caption"
                        component="span"
                        sx={{ color: "primary.main" }}
                    >
                        <Typography
                            variant="body1"
                            component="span"
                            sx={{ fontWeight: "bold" }}
                        >
                            $
                            {

                                productID?.price
                            }

                        </Typography>
                        /{" "}
                        {

                            productID?.per
                        }
                    </Typography>
                </Box>


            </CardContent>
            <CardActions>
                <Stack
                    direction="row"
                    sx={{ justifyContent: "space-between", width: "100%" }}
                >
                    <Box>
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{ alignItem: "center", cursor: "pointer" }}
                        >

                            <Avatar
                                sx={{ textDecoration: "none", width: "30px", height: "30px" }}
                                component={Link}
                                to={`/rent-profile/${productID?.userID}`}
                            >
                                {productID?.userID?.profilePicture ? (
                                    <img
                                        src={ownerID?.profilePicture}
                                        alt={ownerID?.username || ownerID?.displayName}
                                        style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                                        loading="lazy"
                                    />
                                ) : (
                                ownerID?.username?ownerID?.username[0].toUpperCase():"" ||
                                ownerID?.displayName?ownerID?.displayName[0].toUpperCase():""
                                )}
                            </Avatar>
                            <Typography
                                gutterBottom
                                variant="body2"
                                component="span"
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    lineHeight: "30px",
                                    color: "primary.main",
                                }}
                            >
                                {

                                    ownerID?.username
                                }
                            </Typography>
                        </Stack>
                    </Box>
                </Stack>
            </CardActions>
        </Card>
    )
}
