import { Avatar, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const MessageItem = ({ message, theme }) => (
    <Stack
        key={message._id}
        sx={{
            backgroundColor: "text.main",
            borderRadius: "10px",
            p: 2,
            position: "relative",
        }}
    >
        <Stack direction="row" sx={{ alignItems: "center" }}>
            <Avatar
                sx={{ textDecoration: "none", width: "30px", height: "30px", mr: 1 }}
            >
                {message.sender.profilePicture ? (
                    <img
                        src={message.sender.profilePicture}
                        alt={message?.sender?.username || message?.sender?.displayName}
                        loading="lazy"
                        style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                    />
                ) : (
                    message?.sender?.username?.[0].toUpperCase() || message?.sender?.displayName?.[0].toUpperCase()
                )}
            </Avatar>
            <Link
                to={`/contactWithUser/${message.sender._id}/${message.sender.username}`}
                style={{
                    textDecoration: "none",
                    color: theme.palette.mode === "dark" ? "white" : theme.palette.primary.main,
                    fontSize: "14px",
                    fontWeight: "bold"
                }}
            >
                {message?.sender?.username || message?.sender?.displayName}
            </Link>
        </Stack>
        <Stack sx={{ pl: 2, mt: 2 }}>
            <Typography variant="body1">{message.content}</Typography>
        </Stack>
    </Stack>
);
