import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../axios/axiosInstance";
import { getUser } from "../../../redux/slices/AuthUser";
import { uploadImage } from "../../../helper/UploadImage";
import { useShowAlert } from "../../../hooks/useShowAlert";
import { useTranslation } from "react-i18next";
import { useShowEmailPopup } from "../../../hooks/useShowEmailPopup";
const useProductForm = () => {
  const checkEmail = useShowEmailPopup();
  const [imageLoading, setImageLoading] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const Authuser = useSelector(getUser);
  const { showAlert } = useShowAlert();

  const { t } = useTranslation("add-product");
  const handleImageChange = async (event, index, setFieldValue) => {
    const selectedImage = event.target.files[0];
    setImageLoading(prevState => ({ ...prevState, [index]: true }));
    try {
      const imageUrl = await uploadImage(selectedImage);
      if (imageUrl) {
        setFieldValue(`pictures.${index}`, imageUrl);
      } else {
        showAlert("error", t("imageUploadFailed"));
      }
    } finally {
      setImageLoading(prevState => ({ ...prevState, [index]: false }));
    }
  };

  const onSubmit = async (values, { resetForm }) => {
    // check if user email is not updated or not exist
    // Check if user email is not updated or not exist
    if (checkEmail("owner")) {
      return; // Exit the function if checkEmail returns true
    }
    if (!Authuser?.user.email) {
      localStorage.setItem(
        "productSaved",
        JSON.stringify(values)
      );
      return;
    }
    // check if user location is not updated or not exist
    if (!Authuser?.user.location?.lat || !Authuser?.user.location?.lng) {
      navigate(`/save-address`);
      localStorage.setItem(
        "productSaved",
        JSON.stringify(values)
      );
      showAlert("error", t("updateLocation"));
      return;
    }


    setLoading(true);
    const finalValues = {
      ...values,
      userID: Authuser?.id,
    };
    axiosInstance.post("/products", finalValues).then((res) => {
      showAlert("success", res.data.message);
      localStorage.removeItem("productSaved");
      resetForm();
    }).catch((err) => {
      showAlert("error", err.response.data.error);
    }).finally(() => {
      setLoading(false);
    });

  };

  return { handleImageChange, imageLoading, onSubmit, loading };
};

export default useProductForm;
