import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../axios/axiosInstance";
import { uploadImage } from "../../../../helper/UploadImage";
import { useShowAlert } from "../../../../hooks/useShowAlert";

const useEditCategory = () => {
  const { showAlert } = useShowAlert();
  const [category, setCategory] = useState({ name: "", picture: "" });
  const [imageUploading, setImageUploading] = useState(false);
  const { id } = useParams();
  // handle category name change
  const handleCategoryNameChange = (event) => {
    setCategory({ ...category, name: event.target.value });
  };

  // handle image change
  const handleImageChange = async (event) => {
    try {
      setImageUploading(true);
      const selectedImage = event.target.files[0];
      const imageUrl = await uploadImage(selectedImage);
      if (!imageUrl) {
        showAlert("error", "Image upload failed");
      }
      setCategory({ ...category, picture: imageUrl });
      setImageUploading(false);
    } catch (err) {
      setImageUploading(false);
      showAlert("error", err.message);
    }
  };

  // handle edit category
  const handleEditCategory = () => {
    axiosInstance.put(`admin/categories/${id}`, category).then(() => {
      showAlert("success", "Category edited successfully");
    }).catch((err) => {
      showAlert("error", err.response.data.error);
    });
  };

  // get category details
  useEffect(() => {
    axiosInstance.get(`admin/categories/${id}`).then((res) => {
      setCategory({
        name: res.data.category.name,
        picture: res.data.category.picture,
      });
    }).catch(() => {
      showAlert("error", "Failed to fetch category details");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    category,
    imageUploading,
    handleCategoryNameChange,
    handleImageChange,
    handleEditCategory,
  };
};

export default useEditCategory;
