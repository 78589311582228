import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState, useEffect } from 'react';
import axiosInstance from '../../../axios/axiosInstance';
import { useSelector } from 'react-redux';
import { getUser } from '../../../redux/slices/AuthUser';
import { useTranslation } from 'react-i18next';
import { useShowAlert } from '../../../hooks/useShowAlert';
const Item = ({ item, type, onEdit, onDelete }) => {
    const { showAlert } = useShowAlert();
    const { t } = useTranslation("product-details");
    const authUser = useSelector(getUser);
    const [average, setAverage] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // Handle delete
    const handleDelete = (ID) => {
        setAnchorEl(null);
        axiosInstance
            .delete(`/${type}/${ID}`)
            .then((res) => {
                showAlert("success", res.data.message);
                onDelete?.(); // Optional callback
            })
            .catch((err) => {
                showAlert("error", err.response.data.error);
            });
    };

    // Handle edit
    const handleEdit = () => {
        setAnchorEl(null);
        onEdit?.(); // Optional callback
    };

    // Fetch average rating
    useEffect(() => {
        const getRatingAverage = () => {
            axiosInstance.post(`ratings/average-rating`, {
                id: item.sender?._id || item.userID?._id || "",
            }).then(res => {
                setAverage(res.data.rating);
            });
        };
        getRatingAverage();
    }, [item, type]);

    return (
        <Box sx={{ backgroundColor: "text.main", p: 1, borderRadius: "5px" }}>
            <Stack direction="row" spacing={2} justifyContent="space-between">
                <Stack direction="row" spacing={2}>
                    <Typography variant="h6" component="div">
                        {item.sender?.username || item.userID?.username}
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ display: "flex", alignItems: "center" }}>
                        <StarIcon sx={{ color: "primary.main" }} />
                        <Typography variant="body1" component="div" sx={{ color: "secondary.main" }}>
                            {average} stars
                        </Typography>
                    </Stack>
                </Stack>
                {
                    authUser?.id === (item.sender?._id || item.userID?._id) && (
                        <Box>
                            <MoreVertIcon
                                id="demo-positioned-button"
                                aria-controls={open ? "demo-positioned-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(event) => setAnchorEl(event.currentTarget)}
                                sx={{ cursor: "pointer" }}
                            />
                            <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <MenuItem onClick={handleEdit}>
                                    {t(`${type}.edit`)}
                                </MenuItem>
                                <MenuItem onClick={() => handleDelete(item._id)}>
                                    {t(`${type}.delete`)}
                                </MenuItem>
                            </Menu>
                        </Box>
                    )
                }
            </Stack>
            <Typography variant="body1" component="div">
                {item.content || item.review}
            </Typography>
        </Box>
    );
};

export default Item;
