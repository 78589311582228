import { Button, TableCell, TableRow } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import useUserActions from "./hooks/useUserActions"; 

export const TableRowUser = ({ user }) => {
  const {
    userDeleted,
    userBlocked,
    handleGoToUserTransactions,
    handleGoToEditUser,
    handleDeleteUser,
    handleRestoreUser,
    handleBlockUser,
    handleUnblockUser,
  } = useUserActions(user);

  return (
    <TableRow key={user.username} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell align="center">{user?.username || user?.displayName}</TableCell>
      <TableCell align="center">{user?.email}</TableCell>
      <TableCell align="center">
        {user?.phoneNumber ? (
          user.phoneNumber
        ) : (
          <PhoneDisabledIcon sx={{ color: "shadow.main" }} />
        )}
      </TableCell>
      <TableCell align="center">
        <Button
          variant="text"
          size="small"
          sx={{ fontWeight: "bold", color: "primary.main" }}
          onClick={handleGoToUserTransactions}
        >
          Transactions
        </Button>
      </TableCell>
      <TableCell align="center">
        <EditIcon
          sx={{ color: "primary.main", cursor: "pointer" }}
          onClick={handleGoToEditUser}
        />
      </TableCell>
      <TableCell align="center">
        {userDeleted ? (
          <Button
            variant="text"
            size="small"
            sx={{
              fontWeight: "bold",
              color: "primary.main",
              px: 3,
            }}
            onClick={() => handleRestoreUser(user._id)}
          >
            <RestoreIcon sx={{ color: "primary.main", cursor: "pointer" }} />
          </Button>
        ) : (
          <Button
            variant="text"
            size="small"
            sx={{ fontWeight: "bold", color: "primary.main" }}
            onClick={() => handleDeleteUser(user._id)}
          >
            <DeleteIcon sx={{ color: "primary.main", cursor: "pointer" }} />
          </Button>
        )}
      </TableCell>
      <TableCell align="center">
        {userBlocked ? (
          <Button
            variant="text"
            size="small"
            sx={{
              fontWeight: "bold",
              color: "primary.main",
              textTransform: "capitalize",
            }}
            onClick={() => handleUnblockUser(user._id)}
          >
            Unblock
          </Button>
        ) : (
          <Button
            variant="text"
            size="small"
            sx={{
              fontWeight: "bold",
              color: "primary.main",
              textTransform: "capitalize",
            }}
            onClick={() => handleBlockUser(user._id)}
          >
            Block
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
